import '../../styles/main.scss';

(function ( $ ) {
    // @ts-ignore
    if ( window.WebCmsComponentProfileRegistry ) {
        // @ts-ignore
        var tokens = window.tokens;

        // @ts-ignore
        WebCmsComponentProfileRegistry.registerProfileForComponentType( 'rich-text', /* component type */'rich-text', /* profile name */{
            _parentProfile: 'default', // toolbar1: 'undo redo | tokens',
            plugins: 'autoresize image contextmenu table hr pagebreak code noneditable lists advlist link paste textcolor charmap anchor',
            toolbar1: 'tokens | undo redo | insert | styleselect | bold italic forecolor backcolor ' +
                '| outdent indent | bullist numlist table | link image code | pastetext',

            // The noneditable_regexp option transforms matched text into spans which is then
            // visually styled to look like tags.
            // https://www.tiny.cloud/docs/plugins/noneditable/
            noneditable_regexp: /@@[^@]+@@/g, // matches @@handlebar@@,

            textcolor_map: [
                "68b42d", "Primary",
                "6c757d", "Secondary",
                "000000", "Black",
                "993300", "Burnt orange",
                "333300", "Dark olive",
                "003300", "Dark green",
                "003366", "Dark azure",
                "000080", "Navy Blue",
                "333399", "Indigo",
                "333333", "Very dark gray",
                "800000", "Maroon",
                "FF6600", "Orange",
                "808000", "Olive",
                "008000", "Green",
                "008080", "Teal",
                "0000FF", "Blue",
                "666699", "Grayish blue",
                "808080", "Gray",
                "FF0000", "Red",
                "FF9900", "Amber",
                "99CC00", "Yellow green",
                "339966", "Sea green",
                "33CCCC", "Turquoise",
                "3366FF", "Royal blue",
                "800080", "Purple",
                "999999", "Medium gray",
                "FF00FF", "Magenta",
                "FFCC00", "Gold",
                "FFFF00", "Yellow",
                "00FF00", "Lime",
                "00FFFF", "Aqua",
                "00CCFF", "Sky blue",
                "993366", "Red violet",
                "FFFFFF", "White",
                "FF99CC", "Pink",
                "FFCC99", "Peach",
                "FFFF99", "Light yellow",
                "CCFFCC", "Pale green",
                "CCFFFF", "Pale cyan",
                "99CCFF", "Light sky blue",
                "CC99FF", "Plum"
            ],

            setup: function ( editor: any ) {
                editor.addButton( 'tokens', {
                    type: 'menubutton', text: 'Tokens', icon: false, menu: tokens.map( ( token: any ) => {
                        return {
                            text: token.label, onclick: function () {
                                editor.insertContent( token.value );
                            }
                        }
                    } )
                } );
            },

            // The following css will be injected into the editor, extending
            // the default styles.
            // In a real world scenario, with much more custom styles for headings
            // links, tables, images etc, it's recommended to use the content_css
            // option to load a separate CSS file. Makes editing easier too.
            // https://www.tiny.cloud/docs/configure/content-appearance/#content_style
            // https://www.tiny.cloud/docs/configure/content-appearance/#content_css
            content_style: `
                .mceNonEditable {
                    background-color: #D6F0FF;
                    padding: 1px 0;
                    color: #44719B;
                    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
                    font-size: 0.9375em;
                }
            `,
        } );
    }

    if ( window.EntityModule ) {
        EntityModule.registerInitializer( function ( node ) {
            /**
             * Initialize depends-on conditions between controls.
             */
            $( '[data-dependson-custom]', node ).each( function ( ix, n ) {
                const dependsonConfig = $( this ).data( 'dependson-custom' );
                const options = dependsonConfig['options'] != null ? dependsonConfig['options'] : {hide: false};
                const orDependencies = dependsonConfig['orDependencies'] != null ? dependsonConfig['orDependencies'] : [];
                delete dependsonConfig['options'];
                delete dependsonConfig['orDependencies'];
                // @ts-ignore
                $( n ).dependsOn( dependsonConfig, options ).or( orDependencies );
            } );

            // @ts-ignore
            if ( window.tinyMCE ) {
                // @ts-ignore
                tinyMCE.init( {
                                  selector: ".tiny-mce-editor",
                                  plugins: "lists link",
                                  toolbar: "bold italic | numlist bullist | link unlink",
                                  menubar: false,
                              } );
            }
        } );

        EntityModule.registerInitializer( function ( node ) {
            function configureDependsOn( dependencySets: any[], n: any, options: any )
            {
                if ( dependencySets.length == 1 ) {
                    // @ts-ignore
                    $( n ).dependsOn( dependencySets[0], options );

                    console.log( 'single dependson set', dependencySets[0] );
                }
                else {
                    // @ts-ignore
                    const dependsOn: any = $( n ).dependsOn( dependencySets[0], options );
                    const remainingSets: any[] = dependencySets.slice( 1 );
                    for ( let i = 0; i < remainingSets.length; i++ ) {
                        dependsOn.or( remainingSets[i] );
                    }
                    console.log( 'multiple sets with or', dependsOn );
                }
            }

            function configureDependsOnForEmbeddedCollection( dependencySets: any[], n: any, options: any )
            {
                let $self = $( n );

                const $itemContainer = $self.closest( '[data-role="item"]' );
                const itemKey = $itemContainer.data( "item-key" );

                const newDependencySets: any[] = [];
                dependencySets.forEach(
                    dependencySet => {
                        const newDependencySet: { [key: string]: any } = {};
                        Object.keys( dependencySet )
                            .forEach(
                                function ( key ) {
                                    if ( key.includes( '[]' ) ) {
                                        newDependencySet[`[data-item-key=${itemKey}] ${key}`] = dependencySet[key];
                                    }
                                    else {
                                        newDependencySet[key] = dependencySet[key];
                                    }
                                }
                            );
                        newDependencySets.push( newDependencySet )
                    }
                );

                console.log( "dependson config", $self, newDependencySets );

                configureDependsOn( newDependencySets, n, options );
            }

            /**
             * Initialize depends-on conditions between controls.
             */
            $( '[data-dependson-izen]', node ).each( function ( ix, n ) {
                const dependsonConfig = $( this ).data( 'dependson-izen' );
                console.log( 'data-dependson-izen', dependsonConfig );
                const options = dependsonConfig['options'];
                const dependencySets = dependsonConfig['dependencySets'];
                const isForEmbeddedCollection = dependsonConfig['isForEmbeddedCollection'];
                if ( dependencySets && dependencySets.length > 0 ) {
                    if ( !isForEmbeddedCollection ) {
                        configureDependsOn( dependencySets, n, options );
                    }
                    else {
                        configureDependsOnForEmbeddedCollection( dependencySets, n, options );
                    }

                }
            } );
        } );

        EntityModule.registerInitializer( function ( node ) {
            $( '[data-dependson-embedded-collection]', node ).each(
                function ( ix, n ) {
                    let $self = $( this );
                    let value;
                    const dependsonConfig = $self.data( 'dependson-embedded-collection' );
                    const options = dependsonConfig['options'] != null ? dependsonConfig['options'] : {hide: false};
                    //Check options contains value, to hide specific value instead of the whole element
                    if ( !!options.value ) {
                        value = $( n ).find( `[value=${options.value}]` );
                    }
                    const orDependencies = dependsonConfig['orDependencies'] != null ? dependsonConfig['orDependencies'] : [];
                    delete dependsonConfig['options'];

                    const $itemContainer = $self.closest( '[data-role="item"]' );
                    const itemKey = $itemContainer.data( "item-key" );

                    const newConfig: { [key: string]: any } = {};
                    Object.keys( dependsonConfig )
                        .forEach(
                            function ( key ) {
                                newConfig[`[data-item-key=${itemKey}] ${key}`] = dependsonConfig[key];
                            }
                        );

                    console.log( "dependson config", $self, newConfig );
                    if ( orDependencies.length > 0 ) {
                        console.error( "dependson containing or not implemented yet for embedded collcetions", newConfig );
                        //     // @ts-ignore
                        //     $( n ).dependsOn( newConfig, options ).or( orDependencies );
                    }
                    else {
                        if ( !!value ) {
                            $( n ).show();
                            // @ts-ignore
                            $( value ).dependsOn( newConfig, options );
                        }
                        else {
                            // @ts-ignore
                            $( n ).dependsOn( newConfig, options );
                        }
                    }
                }
            )
        } );
    }

    // @ts-ignore
    window.getAllQueryFilterValues = function () {
        const temp: any = {};
        $( '[data-entity-query-property]' )
            .each( ( index, element ) => {

                // @ts-ignore
                console.log( $( element ).data() );
                // @ts-ignore
                let adapter = $( element ).data( "bootstrapui-adapter" );

                const property = $( adapter.getTarget() ).data( 'entity-query-property' );
                const value = adapter.getValue().map( ( av: any ) => av.value );

                temp[property] = value;
            } );
        return temp;
    };

})( jQuery );
